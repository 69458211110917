.modal {
  overflow: visible;
}

.container {
  width: 100%;
}

.headercontainer {
  height: 120px;
  width: 80%;
  padding-left: 10%;
  padding-bottom: 10px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
}

.header {
  width: 90%;
  display: flex;
  align-items: center;
}

.questioncontainer {
  margin-top: 20px;
}

.questionmodal {
  width: 270px;
}

.input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input1 {
  margin-bottom: 2%;
  width: 80%;
}

.input2 {
  width: 80%;
}

.input3 {
  width: 80%;
  margin-top: 2%;
}

.main {
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  padding-left: 10%;
}

.button {
  height: 50px;
  width: 40%;
  background-color: #ff8d5c;
  border: 1px solid #ff8d5c;
  color: white;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}

.filebutton {
  width: 200px;
  height: 50px;
  display: none;
}

.listcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 3%;
  width: 80%;
  margin-left: 10%;
  margin-bottom: 15px;
}

.listelement {
  width: 100%;
  height: 50px;
  border: 1px solid #f8f7f8;
  border-radius: 10px;
  background-color: #f8f7f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin-left: 5%;
  padding-left: 5%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.removefile {
  height: 40px;
  width: 40px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttoncontainer {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.savebutton {
  margin-top: 20px;
  height: 60px;
  width: 250px;
  margin-bottom: 20px;
}
