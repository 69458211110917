.container {
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
}

.circle {
  width: 100%;
  height: 100%;
  background-color: #ff8d5c;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: default;
}

.question {
  height: 40%;
  width: 30%;
}

.modal {
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  position: absolute;
  box-sizing: border-box;
  z-index: 200;
  padding: 15px;
  font-weight: 500;
  top: calc(100% + 5px);
  cursor: default;
}
