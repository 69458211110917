.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  background: rgba(23, 30, 68, 0.18);
  backdrop-filter: blur(1px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overscroll-behavior: contain;
}

.modal {
  position: fixed;
  min-block-size: min-content;
  height: fit-content;
  width: 36rem;
  max-width: 36rem;
  left: calc(50% - 18rem);
  top: 20%;
  bottom: 30%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 100ms ease-out forwards;
}

.closeBtn {
  position: absolute;
  right: 15px;
  top: 15px;
  height: 40px;
  width: 40px;
  border: 1px solid #e8e8e7;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: rgb(184, 184, 184);
    cursor: pointer;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}