.input {
  border: 1px solid #e8e5e7;
  border-radius: 10px;
  height: 50px;
  padding-left: 15px;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  padding-top: 0;
  padding-bottom: 0;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; 
}

Input::placeholder {
  font-size: 16px;
  color: #868085;
}
