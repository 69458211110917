.button {
  height: 50px;
  width: 200px;
  background-color: #fcda67;
  color: #171E44;
  border: 1px solid #fcda67;
  border-radius: 30px;
  font-weight: 700;

  &:hover {
    background-color: #e9bd28;
    border: 1px solid #e9bd28;
  }
}