@font-face {
  font-family: "Satoshi";
  font-weight: 300 900;
  src: url("./fonts/satoshi-variable.woff2");
}

body {
  zoom: 0.8;
  background-color: #fdf4f4;
  font-family: "Satoshi", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
div,
input,
textarea,
button {
  color: #171e44;
  font-family: "Satoshi", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.rpv-core__text-layer {
  display: none;
}

.react-datepicker {
  border: 1px solid rgb(219, 219, 219) !important;
  box-shadow: 8px 20px 240px rgba(0, 0, 0, 0.03) !important;
  padding: 8%;
  background-color: #fcfcfc !important;
  border-radius: 20px !important;
  color: #171e44 !important;
  font-family: "Satoshi" !important;
}

.react-datepicker__month-container {
  height: auto;
}

.react-datepicker__navigation {
  height: 40px !important;
  width: 40px !important;
  top: 20px !important;
  background-color: white !important;
  border: 1px solid white !important;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__navigation-icon::before {
  color: #171e44 !important;
}

.react-datepicker__navigation--previous {
  height: auto;
  left: 8% !important;
}

.react-datepicker__navigation--next {
  height: auto;
  right: 8% !important;
}

.react-datepicker__header {
  background-color: #fcfcfc !important;
  height: auto;
  border: 0px solid transparent !important;
}

.react-datepicker__day-name {
  color: rgb(140, 140, 140) !important;
}

.react-datepicker__current-month {
  height: auto;
  margin-bottom: 20px;
}

.react-datepicker__month {
  height: auto;
}

.react-datepicker__week {
  height: auto;
}

.react-datepicker__day {
  height: auto;
  color: #171e44 !important;
  font-weight: 500;
}

.react-datepicker__day--selected {
  background-color: #fcda67 !important;
  border-radius: 13px !important;
  color: #171e44;
}

.react-datepicker__day--keyboard-selected {
  background-color: #fff !important;
  border-radius: 13px !important;
  color: #171e44;
}

.react-datepicker__day--today {
  font-weight: 1000 !important;
}

.react-datepicker__triangle {
  display: none;
}

#front-chat-iframe {
  min-height: 100px;
  min-width: 100px;
}
